// React
import React, { useState, useEffect } from 'react';
// Firebase
// Local
import './App.css';
import SignInPage from './shared/pages/SignInPage';
import NotAuthorizedPage from './shared/pages/NotAuthorizedPage';
import AppMain from './AppMain';
import LoadingOverlay from './shared/LoadingOverlay';
import { initFirebase, refreshAuthToken } from './shared/firebase/FirebaseUtil';


const AppBase = () => {
	const [fb, setFB] = useState(undefined);
  	const [user, setUser] = useState(undefined);
  	const [clientId, setClientId] = useState(undefined);
  	const [client, setClient] = useState(undefined);
  	const [needsClaimRefresh, setNeedsClaimRefresh] = useState(true);


	// Startup on the first run
	useEffect(() => {
		initFirebase().then( _fb => {
			setFB(_fb);

			// Wake up the functions if they fell asleep :)
			_fb.functions.wakeUp().then().catch( err => {
				console.error( "Error waking up functions", err );
			});

			// Handle user login/logout
			_fb.auth.onAuthStateChanged(
				user => {
					if( user === undefined || user === null )
						console.log('Signed out');
					else
						console.log('Signed In: ', user.uid);
					setUser(user)
				}, 
				error => {
			    	console.error('auth error');
			    	console.error(error);
		    });
		}).catch( (error) => {
			console.error("Unable to initialize firebase: " + error);
		});
	},[]);

	// Get Client Id and Name
	useEffect(() => {
	    if( user === undefined || user === null ) {
	      	setClientId(undefined);
	      	setClient(undefined);
	    }
	    else {
  			const urlClientId = (new URLSearchParams(window.location.search)).get('clientId') || undefined;
	    	fb.functions.getClientWithLinkIfNeeded( { urlClientId: urlClientId }).then( (result) => {
	    		if( ! result.data.success ) {
	    			if( result.data.message === 'ClientId provided but UID is already mapped to a different client.' ) {
	    				console.error('Failed to get client: ', result.data.message);
	    				fb.auth.signOut();
	    			}
	    			else
	    				console.error('Failed to get client: ', result.data.message);
	    		}
	    		else {
	    			if( result.data.data.isNewLink )
					    console.log('Linked user to client id', urlClientId);
					console.log('Retrieved client', result.data.data.clientId);
					setClientId(result.data.data.clientId);
					setClient({ name: result.data.data.name});
	    		}
	    	}).catch( (err) => {
	    		console.error('Failed to get client: ', err);
	    	});
	    }
	}, [fb, user]);

	// Set/refresh submissions claim
	useEffect(() => {
		if( user === undefined|| user === null || needsClaimRefresh === false || clientId === undefined )
			return;
		else {
			fb.functions.setSubmissionsClaim().then( (result) => {
				if( result.data.success !== true )
					console.error('Error in setSubmissionsClaim', result.data.message);
				else {
					console.log('Set submissions claim', result.data.claims);
					refreshAuthToken(user).then( idToken => { 
						console.log('Refreshed token');
						setNeedsClaimRefresh(false) 
					});
				}
			})
			.catch( (error) => {
				console.error("Unhandled error in setSubmissionsClaim", error);
			});
		}
	},[fb, user, needsClaimRefresh, clientId]);

	const signIn = () => {
		const provider = new fb.firebase.auth.GoogleAuthProvider();
	    fb.auth.signInWithRedirect(provider);
	}

	
	if( fb === undefined || user === undefined )
		return ( <LoadingOverlay open={true} /> );
	else if( user === null )
		return ( <SignInPage signIn={signIn} /> );
	else if( user.email.endsWith('@entrywayloans.com') || client === 'not-found' )
		return ( <NotAuthorizedPage auth={fb.auth} /> );
	else if( client === undefined || needsClaimRefresh === true )
		return ( <LoadingOverlay open={true} /> );
	else
		return ( <AppMain fb={fb} user={user} clientId={clientId} client={client} /> );
	

}

export default AppBase;
