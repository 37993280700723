const styles = (theme) => ({
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  pageTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addressField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '350px',
  },
  textField: {
    width: '200px',
  },
  textFieldWide: {
    width: '400px',
  },
  dateField: {
    width: '150px',
  },
  currencyField: {
    width: '150px',
  },
  emailField: {
    width: '300px',
  }
});

export default styles;