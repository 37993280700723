// React
import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';


export const MyRadioGroup = ({direction, value, options, onChange, disabled}) => {  // options: [ {label: 'Red', value: 'red' } ]

  const handleOnChange = (event) => {
    event.preventDefault();
    if( onChange )
      onChange(event.target.value);
  }


  return (
    <RadioGroup   {...(direction === 'column' ? {} : {row:true})}
                  value={value}
                  onChange={(event) => handleOnChange(event)}
                  disabled={disabled} > 
      {
        options.map( (option) => {
          return ( <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} disabled={disabled} /> );
        } )
      }
    </RadioGroup>
    );
}








