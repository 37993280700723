import React from 'react';
// Material UI
import { Button } from '@material-ui/core';


const NotAuthorized = ({auth}) => {

	return (
		<div>
			<div>Sorry but you are not authorized for this site.</div>
			<Button variant="contained" color="primary" onClick={(e) => auth.signOut()} style={{ marginTop: '50px' }} >
                Sign Out
			</Button>
		</div>
	);
}

export default NotAuthorized;



