// React
import React, { } from 'react';
// Material UI
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const LoadingOverlay = ({ open }) => {
  	const theme = useTheme();

	return ( 
		<Backdrop open={open} style={{zIndex: theme.zIndex.drawer + 1, color: '#fff'}}>
			<CircularProgress color="inherit" />
		</Backdrop> 
	);
}

export default LoadingOverlay;
