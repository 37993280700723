import React from 'react';
// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormControl, FormLabel, TextField, Typography, useMediaQuery } from '@material-ui/core';
// Local
import { AddressComponent, DateField, MyRadioGroup, MyCurrencyField } from '../controls';
import formPageStyles from '../style/FormPageStyles';


const styles = (theme) => ({

});
const mergeStyles = (theme) => {
  const local = styles(theme);
  const form = formPageStyles(theme);
  return Object.assign(form, local);
}
const useStyles = makeStyles(mergeStyles);



export default function LoanApplicationForm({value, onChange, disabled}) {
  const theme = useTheme();
  const classes = useStyles();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));

  const handleOnChangeEvent = (event) => {
    event.preventDefault();
    handleOnChange(event.target.name, event.target.value);
  }

  const handleOnChange = (name, value) => {
    if( onChange )
      onChange(name, value);
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>

      <Typography variant="h3" >Basics</Typography>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">What is your plan?</FormLabel>
        <MyRadioGroup value={value.goal || ''} 
                      options={ [ {value:'fixAndFlip', label:'Fix and flip'}, 
                                  {value:'rehabToRent', label:'Rehab to rent'}, 
                                  {value:'other', label:'Other'} ] }
                      onChange={ (value) => handleOnChange( "goal", value ) } 
                      disabled={disabled} />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Do you own the property?</FormLabel>
        <MyRadioGroup value={value.purchaseStatus || ''} 
                      options={ [ {value:'own', label:'I own it'}, 
                                  {value:'contract', label:"It's under contract"}, 
                                  {value:'looking', label:'Other'} ] }
                      onChange={ (value) => handleOnChange( "purchaseStatus", value ) } 
                      disabled={disabled} />
      </FormControl>
    { value.purchaseStatus === "contract" &&
        <FormControl component="fieldset" className={classes.field}>
          <FormLabel component="legend">When do you want to close?</FormLabel>
          <DateField    
            name="reqCloseDate"
            value={value.reqCloseDate }
            onChange={(value) => handleOnChange('reqCloseDate', value)}
            className={classes.dateField}
            mode={isXS ? 'touch' : 'keyboard'}
            disabled={disabled} 
          />
        </FormControl>
    }
    { value.purchaseStatus === "own" &&
        <FormControl component="fieldset" className={classes.field}>
          <FormLabel component="legend">When did you purchase it?</FormLabel>
          <DateField          
            name="purchaseDate"
            value={value.purchaseDate}
            onChange={(value) => handleOnChange('purchaseDate', value)}
            label='Purchase Date'
            className={classes.dateField}
            mode={isXS ? 'touch' : 'keyboard'}
            disabled={disabled} 
          />
        </FormControl>
    }
      <FormControl component="fieldset" className={classes.addressField}>
        <FormLabel component="legend">What is the property address?</FormLabel>
        <AddressComponent 
            address={value.propAddress || {}} 
            onChange={ (address) => handleOnChange('propAddress',address) }
            disabled={disabled} 
        />
      </FormControl>

      <Typography variant="h3" >Financials</Typography>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Purchase Price</FormLabel>
        <MyCurrencyField
            name="purchasePrice"
            className={classes.currencyField}
            value={value.purchasePrice || ''}
            onChange={(event, value)=> handleOnChange("purchasePrice", value) }
            disabled={disabled} 
          />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Rehab Budget</FormLabel>
        <MyCurrencyField    
          name="rehabBudget"
          className={classes.currencyField}
          value={value.rehabBudget || ''}
          onChange={(event, value) => handleOnChange("rehabBudget", value) }
            disabled={disabled} 
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">As-Is Market Value</FormLabel>
        <MyCurrencyField          
          name="marketValueAsIs"
          className={classes.currencyField}
          value={value.marketValueAsIs || ''}
          onChange={(event, value) => handleOnChange("marketValueAsIs", value) }
          disabled={disabled} 
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">After-Rehab Value</FormLabel>
        <MyCurrencyField          
          name="marketValueAfterRehab"
          className={classes.currencyField}
          value={value.marketValueAfterRehab || ''}
          onChange={(event, value) => handleOnChange("marketValueAfterRehab", value) }
          disabled={disabled} 
        />
      </FormControl>

      <Typography variant="h3" >Ownership</Typography>
        <FormControl component="fieldset" className={classes.field}>
          <FormLabel component="legend">How {value.purchaseStatus === "own" ? "do" : "will"} you own it?</FormLabel>
          <MyRadioGroup value={value.howOwn || ''} 
                        options={ [ {value:'direct', label:'Directly'}, 
                                    {value:'entity', label:'In an entity'} ] }
                        onChange={ (value) => handleOnChange( "howOwn", value ) } 
                        disabled={disabled} />
        </FormControl>
      { value.howOwn === "entity" &&
        <React.Fragment>
          <FormControl component="fieldset" className={classes.field}>
            <FormLabel component="legend">What is the entity's legal name?</FormLabel>
            <TextField          
              id="entityLegalName"
              name="entityLegalName"
              value={value.entityLegalName || ''}
              type="text"
              className={classes.textField}
              onChange={(event) => handleOnChangeEvent(event)}
              disabled={disabled} 
            />
          </FormControl>
          <FormControl component="fieldset" className={classes.field}>
            <FormLabel component="legend">What is the entity's legal form?</FormLabel>
            <MyRadioGroup value={value.entityForm || ''} 
                        options={ [ {value:'llc', label:'LLC'}, 
                                    {value:'corporation', label:'Corporation'}, 
                                    {value:'trust', label:'Trust'}, 
                                    {value:'other', label:'Other'} ] }
                        onChange={ (value) => handleOnChange( "entityForm", value ) }
                        disabled={disabled}  />
          </FormControl>
        </React.Fragment>
      }
        <div>Placeholder for ownership</div>

    <Typography variant="h3" >Miscellaneous</Typography>
      <FormControl component="fieldset" className={classes.field}>
          <FormLabel component="legend">Where did you find this property?</FormLabel>
          <MyRadioGroup value={value.propSource || ''} 
                        options={ [ {value:'mls', label:'MLS'}, 
                                    {value:'auction', label:'Auction'}, 
                                    {value:'wholesale', label:'Wholesale'}, 
                                    {value:'marketing', label:'Direct Marketing'}, 
                                    {value:'other', label:'Other'} ] }
                        onChange={ (value) => handleOnChange( "propSource", value ) }
                        disabled={disabled} />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
          <FormLabel component="legend">How did we most earn your business?</FormLabel>
          <MyRadioGroup value={value.earnBusiness || ''} 
                        options={ [ {value:'ratesTerms', label:'Good rates and terms'}, 
                                    {value:'easy', label:'Easy to work with'}, 
                                    {value:'fast', label:'Fast turnaround'}, 
                                    {value:'referral', label:'Referral'}, 
                                    {value:'other', label:'Other'} ] }
                        onChange={ (value) => handleOnChange( "earnBusiness", value ) }
                        direction="column"
                        disabled={disabled}  />
      </FormControl>
      { value.earnBusiness === "referral" &&
          <FormControl component="fieldset" className={classes.textField}>
            <FormLabel component="legend">Who referred you?</FormLabel>
            <TextField          
              id="referredBy"
              name="referredBy"
              value={value.referredBy || ''}
              type="text"
              onChange={(event) => handleOnChangeEvent(event)}
              disabled={disabled}
            />
          </FormControl>
          }

    </div>
  );

}
