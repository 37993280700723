import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';



export const SquareIconButton = withStyles(theme => ({
  root: { 
    minWidth: 'initial'
  }
}), { withTheme: true })(Button);
