// React
import React, { useContext } from 'react';
// Material UI
//import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Local
import AppContext from './AppContext';
import Frame from './shared/Frame';
import CardItem from './shared/CardItem';
import SectionHeaderRow from './shared/SectionHeaderRow';
import CardListSection from './shared/CardListSection';


//const styles = (theme) => ({});
//const useStyles = makeStyles(styles);


export default function Home({submissions, clientId, client, clientShared}) {
  const {reference} = useContext(AppContext);
//  const classes = useStyles();

  const refClientStatus = reference['client-status'];
  const refApplicationStatus = reference['application-status'];
  
  const thisClientApplicationStatus = clientShared?.application?.status || 'new';
  return (
    <Frame title='Entryway Loans' backLabel={undefined} backTo={undefined} >

	    <SectionHeaderRow text='Borrower Approval' />
      <CardItem 
            title={client.name}
            url={'/client/' + clientId + '/application'}
            right={ <Typography variant="body1"
                                style={{ textTransform: 'uppercase',
                                         color: refApplicationStatus[thisClientApplicationStatus].color }} 
                             >{refApplicationStatus[thisClientApplicationStatus].label}</Typography> }      />


    	<CardListSection
          title='Loans in Progress'
          getCardTitle={(value) => value?.shared?.application?.data?.propAddress?.street || 'New Application' }
          getCardUrl={(value) => '/submissions/' + value.id }
          getCardRight={(value) => (
          				<Typography variant="body1"
                          			style={{ textTransform: 'uppercase',
                                   			color: refClientStatus[value?.shared?.clientStatus].color }} 
                             >{refClientStatus[value?.shared?.clientStatus].label}</Typography> ) }
          values={submissions}      />   

    </Frame>
  );
}
