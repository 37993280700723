// React
import React, { useState, useContext, useEffect } from 'react';
// Reach Router
import { navigate } from '@reach/router';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, List, ListItem } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
// Local
import AppContext from './AppContext';
import FullScreenPopup from './shared/FullScreenPopup';
import MyFileViewer from './shared/MyFileViewer';
import MyFileUpload from './shared/MyFileUpload';
import LoadingOverlay from './shared/LoadingOverlay';



const styles = (theme) => ({

  container: {
    backgroundColor: '#f0f0f0',
  },

  main: {
    // Width
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.md - theme.spacing(2) * 2,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: 0,
      paddingRight: 0,
    },

    display: 'flex',
    flexDirection: 'column',
  },

  statusRow: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  detailText: {
    fontStyle: 'italic',
  },

  footerButton: {
    backgroundColor: theme.palette.grey[300],
    width: '130px',
  },
});
const useStyles = makeStyles(styles);


export default function DocumentRequestForm({submissionId, clientUploadId, clientUploads}) {
  const { storage, functions, loggedInUser, reference} = useContext(AppContext);
  const classes = useStyles();

  const refClientUploadStatus = reference['client-upload-status'];
  const [clientUpload, setClientUpload] = useState(undefined);
  const [refDocType, setRefDocType] = useState(undefined);

  const [showLoading, setShowLoading] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [viewerFilePath, setViewerFilePath] = useState(undefined);
  const [viewerContentType, setViewerContentType] = useState(undefined);
  const [showFileTypeNotViewable, setShowFileTypeNotViewable] = useState(false);

  useEffect(() => {
    if( clientUploads === undefined || clientUploadId === undefined )
      setClientUpload(undefined);
    else
      setClientUpload(clientUploads.find(e => e.id === clientUploadId));
  }, [clientUploads, clientUploadId]);

  useEffect(() => {
    if( reference === undefined || clientUpload === undefined )
      setRefDocType(undefined);
    else
      setRefDocType(reference['doc-types'][clientUpload?.shared?.docType]);
  }, [reference, clientUpload]);

  const appendAlerts = (newAlerts) => {
    setAlerts(alerts.concat(newAlerts));
  }

  const reflect = p => p.then(v => ({v, status: "fulfilled" }), e => ({e, status: "rejected" }));
  const handleUpload = (selectedItems) => {
    if( selectedItems.length === 0 )
      return;

    setShowLoading(true);

    const tempId = Math.random().toString(36).substring(2, 15);

    let promises = selectedItems.map( (item) => {
      return storage.ref('user-temp/' + loggedInUser.uid + '/' + tempId + '/' + item.name ).put(item);
    });

    Promise.all(promises.map(reflect)).then( (results) => {
      let newAlerts = [];

      results.forEach( (x, index) => {
        if( x.status === 'rejected' ) {
          newAlerts.push( 'Unable to upload file "' + selectedItems[index].name + '".' );
          console.error( 'Unable to upload file "' + selectedItems[index].name + '".' );
        }
      });
      
      const data = { submissionId: submissionId, clientUploadId: clientUploadId, tempDirName: tempId };
      functions.commitClientUploadFiles(data).then( (result) => {
        if( result.data.success === true ) {
          appendAlerts(newAlerts);
          setShowLoading(false);
        }
        else {
          appendAlerts(newAlerts.concat(result.data.message));
          setShowLoading(false);
        }
      })
      .catch( (error) => {
        console.error("Error committing document", error);
        setShowLoading(false);
      });

    });
    
  }


  const handleCloseClick = () => {
    navigate('/submissions/' + submissionId);
  }

  const handleDownloadTemplateClick = (e) => {
    e.preventDefault();
    storage.ref('/public/templates/' + refDocType.templateName)
    .getDownloadURL().then( (url) => {
      window.open(url, "_blank");
    }).catch(function(error) {
      console.error(error);
    });
    return false;
  }

  const handleViewFile = (fileDesc) => {
    if( ['application/pdf', "image/jpeg", "image/png"].includes(fileDesc.contentType) ) {
      storage.ref(fileDesc.path).getDownloadURL().then( (url) => {
        setViewerFilePath(url);
        setViewerContentType(fileDesc.contentType);
      }).catch(function(error) {
        console.error(error);
      });
    } else
      setShowFileTypeNotViewable(true);

    return false;
  }

  const handleDownloadFile = (fileDesc) => {
    storage.ref(fileDesc.path).getDownloadURL().then( (url) => {
      window.open(url, "_blank");
    }).catch(function(error) {
      console.error(error);
    });
    return false;
  }

  const handleFileDelete = (fileDesc) => {
    setShowLoading(true);
    const data = { submissionId: submissionId, clientUploadId: clientUploadId, fileName: fileDesc.name };
    functions.deleteClientUploadFile(data).then( (result) => {
      if( result.data.success === true )
        setShowLoading(false);
      else {
        appendAlerts(result.data.message);
        setShowLoading(false);
      }
    })
    .catch( (error) => {
      console.error("Error deleting file", error);
      setShowLoading(false);
    });
  }

  let page = undefined;
  if( clientUpload === undefined )
    page = ( <Typography variant='body1'>Loading...</Typography> );
  else {
    page = (
      <React.Fragment>
        <div className={classes.container} >
          <div className={classes.main} >

            <div className={classes.statusRow}>
              <div className={classes.statusDiv}>
                <Typography variant="body1" className={classes.subTitle}>Status: </Typography>&nbsp;&nbsp;&nbsp;
                <Typography variant="body1"
                  style={{ textTransform: 'uppercase',
                           color: refClientUploadStatus[clientUpload.status].color }} 
                     >{refClientUploadStatus[clientUpload.status].label}</Typography>
              </div>

              { refDocType && refDocType.templateName &&
                <Button variant="outlined" component="span" 
                      onClick={(event) => handleDownloadTemplateClick(event) }
                      startIcon={<GetAppIcon />}>
                  Template
                </Button>
              }
            </div>
            { clientUpload?.shared.reviewComment && clientUpload?.shared.reviewComment.length > 0 &&
              <div className={classes.statusRow}>
                <div className={classes.statusDiv}>
                  <Typography variant="body1" className={classes.subTitle} >Comment: </Typography>&nbsp;&nbsp;&nbsp;
                  <Typography variant="body1" className={classes.detailText} >{clientUpload?.shared.reviewComment}</Typography>
                </div>
              </div>
            }


            { 
              alerts.map( (alert) => {
                return (
                    <Alert key={alert} variant="filled" severity="error" 
                        onClose={(event) => { 
                          const newAlerts = [...alerts];
                          const pos = newAlerts.findIndex((el) => el === alert);
                          newAlerts.splice(pos,1);
                          setAlerts(newAlerts);
                        }}>
                      {alert}
                    </Alert>
                  );
              } )
            }

            <Typography variant="h3" >Files</Typography>
            <MyFileUpload
              files={clientUpload?.shared.files}
              isReadOnly={clientUpload?.shared.reviewResult === 'accepted'}
              onUploadRequested={selectedFiles => handleUpload(selectedFiles)}
              onDeleteRequested={fileDesc => handleFileDelete(fileDesc)}
              onViewFileRequested={fileDesc => handleViewFile(fileDesc)}
              onDownloadFileRequested={fileDesc => handleDownloadFile(fileDesc)}
            />

            { clientUpload?.shared.checklist !== undefined && clientUpload?.shared.checklist.length > 0 &&
              <React.Fragment>
                <Typography variant="h3" >Requirements</Typography>
                <List style={{ paddingTop: '0px' }} >
                  {
                    clientUpload?.shared.checklist.map( (value, index) => 
                      <ListItem key={index}>
                        <Typography variant="body1" >&#8226; {value}</Typography>
                      </ListItem> )
                  }
                </List>
              </React.Fragment>
            }

          </div>
        </div>

        <MyFileViewer 
            open={viewerFilePath !== undefined}
            file={viewerFilePath} 
            contentType={viewerContentType}
            onClose={() => { setViewerFilePath(undefined); setViewerContentType(undefined); }} />

          <Dialog open={showFileTypeNotViewable} onClose={() => setShowFileTypeNotViewable(false)} >
            <DialogTitle id="alert-dialog-title">File not viewable</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This file type is not viewable.  Please download instead.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowFileTypeNotViewable(false)} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>

        <LoadingOverlay open={showLoading} />
      </React.Fragment>
    );
  }

  return (
    <FullScreenPopup  title={clientUpload?.shared?.label || refDocType?.label || 'Unknown'} onCancel={() => handleCloseClick()}
        footerRight={<Button color="primary" onClick={(event) => handleCloseClick()} className={classes.footerButton}>Close</Button>} >
        {page}
    </FullScreenPopup>
  );
}
