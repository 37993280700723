// React
import React from 'react';
// Other Third Party
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
// Local
import GroupedTextField from './GroupedTextField';


export const MyCurrencyField = (props) => {

  let newProps = Object.assign({}, props);
  newProps.currencySymbol = '$';
  newProps.minimumValue= '0';
  newProps.outputFormat='number';
  newProps.decimalCharacter='.';
  newProps.digitGroupSeparator=',';

  return (
    <CurrencyTextField {...newProps}/>
  );
}


export const MyPercentField = (props) => {

  let newProps = Object.assign({}, props);
  newProps.currencySymbol = '';
  newProps.minimumValue= '0';
  newProps.maximumValue='100';
  newProps.outputFormat='number';
  newProps.decimalCharacter='.';

  return (
    <CurrencyTextField {...newProps} 
      InputProps={{endAdornment: <span>%</span>}}/>
  );
}


export const MyPhoneField = (props) => {

  let newProps = Object.assign({}, props);
  newProps.breakAfter = [3,6];
  newProps.breakChar = "-";
  newProps.maxLength = 10;

  return (
    <GroupedTextField {...newProps}  />
    );
}


export const MySSNField = (props) => {

  let newProps = Object.assign({}, props);
  newProps.breakAfter = [3,5];
  newProps.breakChar = "-";
  newProps.maxLength = 9;

  return (
    <GroupedTextField {...newProps}  />
    );
}






