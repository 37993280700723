// React
import React from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import ThreePartRow from './ThreePartRow';


const styles = (theme) => ({
  container: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    height: '100%',
  },

  // Header
  header: {
    height: '56px',
    padding: '0px',
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.primary.contrastText,
  },
  closeButton: {
    width: '48px',
    flexGrow: 0,
    flexShrink: 0,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },

  main: {
    // Height
    overflowY: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    // Width
    width: '100%',
  },
  
  footer: {
    height: '56px',
    padding: '0px',
    backgroundColor: theme.palette.grey[300],
  }
});
const useStyles = makeStyles(styles);


export default function FullScreenPopup({children, title, onCancel, footer, footerLeft, footerCenter, footerRight}) {
  const classes = useStyles();

  if( ! onCancel ) console.log('Warning: onCancel is not defined.');
  
  const handleCancel = () => {
    if( onCancel ) onCancel();
  }
      
  return (
    <div className={classes.container}>
      <ThreePartRow
        center={<Typography variant="h6" className={classes.title}>{title}</Typography>}
        right={<IconButton onClick={(event) => handleCancel()} className={classes.closeButton} ><CloseIcon /></IconButton>}
        className={classes.header}  />

      
      <main className={classes.main}>
        {children}
      </main>


      <ThreePartRow
          left={footerLeft}
          center={footerCenter}
          right={footerRight}
          className={classes.footer}  />
    </div>
  );
}
