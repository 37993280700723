import React from 'react';
// Material UI
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
  },
  street: {
    //width: '100%',
    flexGrow: 0,
    flexShrink: 1,
  },
  row2: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexGrow: 0,
    flexShrink: 1,
  },
  city: {
    //minWidth: '150px',
    flexGrow: 1,
    flexShrink: 1,
  },
  state: {
    width: '30px',
    flexGrow: 0,
    flexShrink: 0,
    marginLeft: '5px',
  },
  zip: {
    marginLeft: '5px',
    width: '100px',
    flexGrow: 0,
    flexShrink: 0,
  },
});
const useStyles = makeStyles(styles);


export const AddressComponent = ({address, onChange, disabled}) => {
  const classes = useStyles();

  const handleOnChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    const newAddress = Object.assign({}, address, { [name]: value });
    onChange(newAddress);
  }

  return (
    <div className={classes.container}>
      <TextField          
        name='street'
        type="text"
        className={classes.street}
        value={address.street || ''}
        onChange={(event) => handleOnChange(event)}
        disabled={disabled}
      />
      <div className={classes.row2}>
        <TextField          
          name='city'
          type="text"
          className={classes.city}
          value={address.city || ''}
          onChange={(event) => handleOnChange(event)}
          disabled={disabled}
        />
        <TextField          
          name='state'
          type="text"
          className={classes.state}
          value={address.state || ''}
          onChange={(event) => handleOnChange(event)}
          disabled={disabled}
        />
        <TextField          
          name='zip'
          type="text"
          className={classes.zip}
          value={address.zip || ''}
          onChange={(event) => handleOnChange(event)}
          disabled={disabled}
        />
      </div>
    </div>
  );
}