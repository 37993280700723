// React
import React, { useState, useEffect, useContext } from 'react';
// Reach Router
import { Router } from "@reach/router"
// Material UI
import { } from '@material-ui/core';
// Local
import AppContext from './AppContext';
import SubmissionMain from './SubmissionMain';
import Frame from './shared/Frame';
import DocumentRequestForm from './DocumentRequestForm';
import ClientUploadClass from './shared/model/ClientUploadClass';
import { handleFirestoreUpdateToArray } from './shared/firebase/FirebaseUtil';
import LoanApplicationPage from './LoanApplicationPage';


export default function SubmissionWrapper({submissionId, clientId, client, clientShared, submissions}) {
  const {db, functions} = useContext(AppContext);
  const [submission, setSubmission] = useState(undefined);
  const [linkedClients, setLinkedClients] = useState(undefined);
  const [clientUploads, setClientUploads] = useState(undefined);

  useEffect(() => {
    if( submissionId === undefined || submissions === undefined ) {
      setSubmission(undefined);
    }
    else {
      const newSubmission = submissions.find(e => e.id === submissionId);
      if( newSubmission === undefined )
        setSubmission('not found');
      else
        setSubmission(newSubmission);
    }
  }, [submissionId, submissions]);

  // Get all the clients attached to this application
  useEffect(() => {
    if( submission === undefined || clientId === undefined )
      setLinkedClients(undefined);
    else {
      const linkedClientIds = submission?.shared?.clients.filter( item => item !== clientId );
      if( linkedClientIds === undefined || linkedClientIds.length === 0 )
        setLinkedClients([]);
      else {
        linkedClientIds.map( clientId => {
          return functions.getLinkedClientStatus({clientId: clientId, submissionId: submissionId}).then( (res) => {
            if( res.data === undefined || res.data.success === undefined )
              console.error('Error getting linked client ' + clientId + ': malformed response.');
            else if( res.data.success === false )
              console.error('Error getting linked client ' + clientId + ': ' + res.data.message);
            else {
              setLinkedClients( (prevState, props) => {
                let newLinkedClients = prevState === undefined ? [] : JSON.parse(JSON.stringify(prevState));
                newLinkedClients.push(Object.assign(res.data.data, { clientId: clientId }));
                return newLinkedClients;
              });
            }
          }).catch( (error) => {
            console.error('Error getting linked client ' + clientId + ':', error);
          });
        });
      }
    }
  }, [functions, submission, clientId, submissionId])

  // Get client_uploads from database
  useEffect(() => {
    var unsub = db.collection('submissions_shared').doc(submissionId).collection('client_uploads')
      .onSnapshot( (querySnapshot) => handleFirestoreUpdateToArray(setClientUploads, querySnapshot, 'shared', ClientUploadClass) );
   
    return function cleanup() {
        unsub();
    }
  }, [db, submissionId]);


  if( submissions === undefined )
    return ( <Frame title='Loan Application' backLabel='Home' backTo='/' ><div>Loading submission</div></Frame> );
  else if( submissions === 'not found' )
    return ( <Frame title='Loan Application' backLabel='Home' backTo='/' ><div>Submission not found.</div></Frame> );
  else 
    return (
      <Router style={{height: '100%'}}>
        <SubmissionMain          path='/'          submission={submission} clientUploads={clientUploads} linkedClients={linkedClients} client={client} clientShared={clientShared}      />
        <DocumentRequestForm  path='doc-request/:clientUploadId'     clientUploads={clientUploads} />
        <LoanApplicationPage     path='application'         submissionId={submissionId}      submission={submission} />
      </Router>
    );
}
