import React from 'react';
// Material UI
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormControl, FormLabel, TextField, Typography, useMediaQuery, FormControlLabel, Checkbox } from '@material-ui/core';
// Local
import { AddressComponent, DateField, MySSNField, MyRadioGroup, MyPhoneField, MyCurrencyField } from '../controls';
import formPageStyles from '../style/FormPageStyles';


const styles = (theme) => ({

});
const mergeStyles = (theme) => {
  const local = styles(theme);
  const form = formPageStyles(theme);
  return Object.assign(form, local);
}
const useStyles = makeStyles(mergeStyles);



export default function ClientApplicationForm({value, onChange, disabled}) {
  const theme = useTheme();
  const classes = useStyles();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  
  const handleOnChangeEvent = (event) => {
    event.preventDefault();
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    handleOnChange(event.target.name, value);
  }

  const handleOnChange = (name, value) => {
    if( onChange )
      onChange(name, value);
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>

      <Typography variant="h3" >Basics</Typography>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Legal Name</FormLabel>
        <TextField          
          name="legalName"
          value={value.legalName || ''}
          type="text"
          style={{width: '300px'}}
          onChange={(event) => handleOnChangeEvent(event)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Date of Birth</FormLabel>
        <DateField     
          name="dob"
          value={value.dob || ''}
          type="date"
          className={classes.dateField}
          onChange={(value) => handleOnChange("dob", value)}
          mode={isXS ? 'touch' : 'keyboard'}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">SSN</FormLabel>
        <MySSNField         
          name="ssn"
          value={value.ssn || ''}
          type="text"
          className={classes.textField}
          onChange={(value) => handleOnChange("ssn", value)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">US Citizenship</FormLabel>
        <MyRadioGroup value={value.citizenship || ''} 
                      options={ [ {value:'citizen', label:'Citizen'}, 
                                  {value:'permanent-resident', label:"Permanent Resident"}, 
                                  {value:'foreign-national', label:'Foreign National'}, 
                                  {value:'other', label:'Other'} ] }
                      onChange={ (value) => handleOnChange( "citizenship", value ) }
                      disabled={disabled} />
      </FormControl>

      <Typography variant="h3" >Contact Points</Typography>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Phone</FormLabel>
        <MyPhoneField          
          name="phone"
          value={value.phone || ''}
          type="text"
          className={classes.textField}
          onChange={(value) => handleOnChange("phone", value)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Email</FormLabel>
        <TextField    
          name="email"
          value={value.email || ''}
          type="text"
          className={classes.emailField}
          onChange={(event) => handleOnChangeEvent(event)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.addressField}>
        <FormLabel component="legend">Home Address</FormLabel>
        <AddressComponent 
            address={value.address || {}} 
            onChange={ (address) => handleOnChange('address', address) }
          disabled={disabled}
        />
      </FormControl>

      <Typography variant="h3" >Experience</Typography>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Lifetime # Flips</FormLabel>
        <TextField          
          name="lifetimeFlips"
          value={value.lifetimeFlips || ''}
          type="text"
          className={classes.textField}
          onChange={(event) => handleOnChangeEvent(event)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Lifetime # Rentals</FormLabel>
        <TextField          
          name="lifetimeRentals"
          value={value.lifetimeRentals || ''}
          type="text"
          className={classes.textField}
          onChange={(event) => handleOnChangeEvent(event)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Lifetime # Wholesales</FormLabel>
        <TextField          
          name="lifetimeWholesales"
          value={value.lifetimeWholesales || ''}
          type="text"
          className={classes.textField}
          onChange={(event) => handleOnChangeEvent(event)}
          disabled={disabled}
        />
      </FormControl>
      <FormControlLabel
        control={<Checkbox checked={value.hasRealEstateLicense || false} onChange={(event) => handleOnChangeEvent(event)} name='hasRealEstateLicense' />}
        label='Real estate license'
        disabled={disabled}
      />
      <FormControlLabel
        control={<Checkbox checked={value.hasContractorLicense || false} onChange={(event) => handleOnChangeEvent(event)} name='hasContractorLicense' />}
        label='Contractor license'
        disabled={disabled}
      />
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Real Estate investing is your</FormLabel>
        <MyRadioGroup value={value.timeCommitment || ''} 
                      options={ [ {value:'primary', label:'Primary occupation'}, 
                                  {value:'sidegig', label:'Side business'}, 
                                  {value:'hobby', label:'Hobby'} ] }
                      onChange={ (value) => handleOnChange( "timeCommitment", value ) }
                      disabled={disabled} />
      </FormControl>

      <Typography variant="h3" >Financials</Typography>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Credit score</FormLabel>
        <TextField          
          name="creditScore"
          value={value.creditScore || ''}
          type="text"
          className={classes.textField}
          onChange={(event) => handleOnChangeEvent(event)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Net worth</FormLabel>
        <MyCurrencyField          
          name="netWorth"
          value={value.netWorth || ''}
          type="text"
          className={classes.textField}
          onChange={(event, value) => handleOnChange("netWorth", value)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Liquid assets</FormLabel>
        <MyCurrencyField          
          name="liquidAssets"
          value={value.liquidAssets || ''}
          type="text"
          className={classes.textField}
          onChange={(event, value) => handleOnChange("liquidAssets", value)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl component="fieldset" className={classes.field}>
        <FormLabel component="legend">Annual Income</FormLabel>
        <MyCurrencyField          
          name="annualIncome"
          value={value.annualIncome || ''}
          type="text"
          className={classes.textField}
          onChange={(event, value) => handleOnChange("annualIncome", value)}
          disabled={disabled}
        />
      </FormControl>

    </div>
  );

}
