// Firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';
// Local
import firebaseConfigLocal from './firebase.config';


export const getFirebaseConfig = () => {
	return fetch('/__/firebase/init.json')
		.then(response => {
			if( !response.ok )
				return firebaseConfigLocal;
			return response.json();
		})
		.catch(error => {
			return firebaseConfigLocal;
		});
}

export const initFirebase = (fbConfig) => {
	const init = (fbConfig) => {
	  	firebase.initializeApp(fbConfig);
	  	const _functions = firebase.app().functions('us-east4');
		return {
			config: fbConfig,
			firebase: firebase,
			auth: firebase.auth(),
			db: firebase.firestore(),
			storage: firebase.storage(),
			functions: {
				// Setting claims
				setIsEmployeeClaim: _functions.httpsCallable('setIsEmployeeClaim'),
				setSubmissionsClaim: _functions.httpsCallable('setSubmissionsClaim'),

				wakeUp: _functions.httpsCallable('wakeUp'),
				inviteClient: _functions.httpsCallable('inviteClient'),
				getClientWithLinkIfNeeded: _functions.httpsCallable('getClientWithLinkIfNeeded'),
				
				saveClientApplication: _functions.httpsCallable('saveClientApplication'),
				submitClientApplication: _functions.httpsCallable('submitClientApplication'),
				getLinkedClientStatus: _functions.httpsCallable('getLinkedClientStatus'),
				
				saveLoanApplication: _functions.httpsCallable('saveLoanApplication'),
				submitLoanApplication: _functions.httpsCallable('submitLoanApplication'),
				
				commitClientUploadFiles: _functions.httpsCallable('commitClientUploadFiles'),
				deleteClientUploadFile: _functions.httpsCallable('deleteClientUploadFile'),
			}
		};
	}

	if( fbConfig === undefined )
		return getFirebaseConfig().then( fbConfig => {
			return init(fbConfig);
		});
	else
		return new Promise((resolve,reject) => {
			resolve();
		}).then( () => {
			return init(fbConfig);
		});
}


export const handleFirestoreUpdate = (setter, querySnapshot, wrapperClass) => {
	setter( (prevState, props) => {
	const newItems = Object.assign({}, prevState);
	querySnapshot.docChanges().forEach( (docChange) => {
	  const doc = docChange.doc;
	  if( docChange.type === 'added' || docChange.type === 'modified' ) {
	    let newItem = doc.data();
	    if( wrapperClass )
	    	newItem = new wrapperClass(newItem);
	    newItems[doc.id] = newItem;
	  }
	  else if( docChange.type === 'removed' )
	    delete newItems[doc.id];
	  else
	    throw Error('Unrecognized change type "' + docChange.type + '".');
	});
	return newItems;
	});
};

export const handleFirestoreUpdateToArray = (setter, querySnapshot, partName, wrapperClass) => {
    setter( (prevState, props) => {
      const newArr = prevState === undefined ? [] : [...prevState];
      querySnapshot.docChanges().forEach( (docChange) => {
        const doc = docChange.doc;
        if( docChange.type === 'added' || docChange.type === 'modified' ) {

          // Find existing item
          let element = newArr.find( e => e.id === doc.id );
          if( element === undefined ) {
          	const newElement = wrapperClass ? new wrapperClass() : {};
          	newElement.id = doc.id;
            const newLength = newArr.push(newElement);
            element = newArr[newLength-1];
          }

          element[partName] = doc.data();
        }
        else if( docChange.type === 'removed' ) {
          const index = newArr.findIndex( e => e.id === doc.id );
          if( index === -1 )
            return;

          // Remove this part from the element
          let element = newArr[index];
          if( element[partName] )
            delete element[partName];

          // If only the id key is left, remove the element
          if( Object.keys(element).length === 1 )
            newArr.splice(index, 1);
        }
        else
          throw Error('Unrecognized change type "' + docChange.type + '".');
      });
      return newArr;
    });
  };


export const refreshAuthToken = (user) => {
	return user.getIdToken(true).then( () => {
		return user.getIdTokenResult()
		.then( (idToken) => idToken )
		.catch( (error) => {
			console.error("Error getting id token result", error);
		});
	}).catch( (error) => {
		console.error("Error getting id token", error);
	});
}