import React, { useState, useRef, useEffect } from 'react';
import { Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { makeStyles } from '@material-ui/core/styles';
import { Document, Page } from 'react-pdf';

import { Swipeable } from 'react-swipeable';

import useWindowDimensions from './style/useWindowDimensions';




import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




export default function MyFileViewer({file, contentType, open, onClose}) {
    const { width } = useWindowDimensions();
    const viewerWidth = 0.9 * Math.min(width, 1100);

    return (
     <MyModal open={open} onClose={onClose} innerWidth={viewerWidth} >
        {
          contentType === "application/pdf" ?
          <MyPDFViewer file={file} width={viewerWidth} />
        : ["image/jpeg", "image/png"].includes(contentType) ?
          <img src={file} width={viewerWidth} style={{display: 'block'}} alt='' />
        :
          <div>Content Type {contentType} not supported.</div>
      }
    </MyModal>
    );
}


const MyModalStyles = (theme) => ({

  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(100, 100, 100, 0.7)',
    zIndex: "90",
  },

  container: {
    position: 'relative',
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    
    marginTop: '20px',
    marginBottom: '20px',
    maxHeight: 'calc(100vh - 40px)',

    width: props => props.innerWidth,
    marginLeft: 'auto',
    marginRight: 'auto',

    outline: '1px solid black',
  },
  main: {
      overflowY: 'auto',
    flexGrow: 1,
    flexShrink: 1,

      width: '100%',
  },

  closeButton: {
    position: 'absolute',
    right: '-16px',
    top: '-16px',
  },
});
const useMyModalStyles = makeStyles(MyModalStyles);


const MyModal = ({children, open, onClose, innerWidth}) => {
    const props = { innerWidth: innerWidth };
    const classes = useMyModalStyles(props);
    const refContainer = useRef(null);

    useEffect(() => {
      refContainer.current.focus();
    });

    const handleKeyUp = (event) => {
      if( event.key === "Escape" )
        onClose();
    }

    return (
 
      <div  className={classes.backdrop} 
            style={open ? {display: 'block'} : {display: 'none'}} 
            onKeyUp={(e) => handleKeyUp(e) }
            ref={refContainer}
            tabIndex="0" >
        <div className={`${classes.container}`}>
          <div className={classes.main} >
            {children}
          </div>
          <Fab size="small" className={classes.closeButton} onClick={() => onClose()}>
            <CloseIcon />
          </Fab>
        </div>
      </div>
    );
}




const MyPDFViewerStyles = (theme) => ({
  backButton: {
    position: 'absolute',
    left: '-16px',
    top: 'calc(50% - 10px)',
  },
  nextButton: {
    position: 'absolute',
    right: '-16px',
    top: 'calc(50% - 10px)',
  },
});
const useMyPDFViewerStyles = makeStyles(MyPDFViewerStyles);


export const MyPDFViewer = ({file, width}) => {
  //const handlers = useSwipeable({ onSwipedRight: (eventData) => setDisplay(eventData), ...{} })
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const classes = useMyPDFViewerStyles();

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
    }

    function changePage(offset) {
      const newPageNumber = pageNumber + offset;
      if( newPageNumber >= 1 && newPageNumber <= numPages )
        setPageNumber(newPageNumber);
    }

    return (
      <Swipeable onSwipedRight={(eventData) => changePage(1)} onSwipedLeft={(eventData) => changePage(-1)} >
        <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode='svg' >
          <Page
              pageNumber={pageNumber} 
              renderTextLayer={false}
              renderAnnotationLayer={false}
              renderInteractiveForms={false}
              width={width}
              scale={1.0}  
              />
          <Fab size="small" className={classes.backButton} onClick={() => changePage(-1)} disabled={pageNumber === 1}>
              <NavigateBeforeIcon />
          </Fab>
          <Fab size="small" className={classes.nextButton} onClick={() => changePage(1)} disabled={pageNumber === numPages}>
              <NavigateNextIcon />
          </Fab>
        </Document>
      </Swipeable>
    );
}

