import React, { useState } from 'react';
// Material UI
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SquareIconButton } from '.';


const styles = (theme) => ({
  popoverContent: {
    padding: '10px',
  },
  filterButton: {
    backgroundColor: 'transparent',
  },
});
const useStyles = makeStyles(styles);



export const IconButtonWithPopup = ({children, icon}) => {
  	const classes = useStyles();
  	const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  	return (
		<div>
          <SquareIconButton className={classes.filterButton} onClick={(event) => { setFilterAnchorEl(event.currentTarget)} }>
            {icon}
          </SquareIconButton>
          <Popover
            open={Boolean(filterAnchorEl)}
            anchorEl={filterAnchorEl}
            onClose={() => {setFilterAnchorEl(null)}}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
            className={classes.filterPopover}
          >
            <div className={classes.popoverContent}>
              {children}
            </div>
          </Popover>
        </div>

   	);
}