class ClientUploadClass {

	constructor(dataOrDocType) {
		this.shared = {};
		if( dataOrDocType === undefined || dataOrDocType === null ) {
			this.shared.docType = undefined;
			this.shared.files = {};
		}
		else if( typeof dataOrDocType === 'object' ) {
			Object.getOwnPropertyNames(dataOrDocType).forEach((name) => {
				this[name] = dataOrDocType[name];
			});
		}
		else if( typeof dataOrDocType === 'string' ) {
			this.shared.docType = dataOrDocType;
			this.shared.files = {};
		}
		else
			throw new Error('Unsupported input: ' + dataOrDocType);

		if( this.shared.checklist === undefined )
			this.shared.checklist = [];
		if( this.shared.checklistResults === undefined )
			this.shared.checklistResults = Array(this.shared.checklist.length).fill(false);
	}
	
	get status() {
		return this.shared.reviewResult === 'accepted' ? 'accepted' 
				: this.shared.reviewResult === 'rejected' ? 'rejected'
				: (this.shared.files === undefined || Object.keys(this.shared.files).length > 0) ? 'uploaded' 
				: 'new';
	}

	clone() {
		return new ClientUploadClass(JSON.parse(JSON.stringify(this)));
	}

	toSharedPOJO() {
		return JSON.parse(JSON.stringify(this.shared));
	}
};

export default ClientUploadClass;