// React
import React from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardActionArea, CardContent } from '@material-ui/core';
// Reach Router
import { Link } from "@reach/router"


const styles = (theme) => ({

  card: {
    //height: '68px'
  },
  cardActionArea: {
    //height: '100%',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

});
const useStyles = makeStyles(styles);


export const CardItem = ({title, url, right, disabled}) => {
  const classes = useStyles();

  if( disabled )
    return (
      <Card className={classes.card} >
        <div style={{ cursor: 'not-allowed', position: 'relative' }}>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#FFFFFF', opacity: '50%' }} />
          <CardContent className={classes.contentBox}>
            <Typography variant='h4' noWrap={true} >{title}</Typography>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {right}
            </div>
          </CardContent>
        </div>
      </Card> );
  else
    return (
      <Card className={classes.card} >
        <CardActionArea to={url || ''} component={Link}>
          <CardContent className={classes.contentBox}>
            <Typography variant='h4' noWrap={true} >{title}</Typography>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {right}
            </div>
          </CardContent>
        </CardActionArea>
      </Card> );

}


export default CardItem;
