import React, {useState} from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Button, CardActionArea } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// Other Third-party
import { DropzoneArea } from 'material-ui-dropzone';
//import MyDropzone from './MyDropzone';
import ConfirmDialog from './dialogs/ConfirmDialog';


const styles = (theme) => ({
  dropZone: {
    minHeight: 'initial',
    fontSize: 'small',
  },

  dropZoneText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '18px',
  },

  dropZonePreviewGrid: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  dropZonePreviewChip: {
  },

  card: {
    //height: '68px'
  },
  cardActionArea: {
    //height: '100%',
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: '700',
    fontSize: '18px',
  },

});
const useStyles = makeStyles(styles);


const MyFileUpload = ({ files, isReadOnly, onUploadRequested, onDeleteRequested, onViewFileRequested, onDownloadFileRequested }) => {
  const classes = useStyles();

  const [pendingDeleteFileDesc, setPendingDeleteFileDesc] = useState(undefined);


  const handleDeleteFileClick = (e, fileDesc) => {
    e.stopPropagation();
    setPendingDeleteFileDesc(fileDesc);
  }

  if( files === undefined )
    return ( <div>Loading...</div> );

  return (
    <div>

        { Object.keys(files).map( (id) => {
            const fileDesc = files[id]; 
            return (
                <Card key={fileDesc.name} className={classes.card} >
                  <CardActionArea onClick={(event) => {
                                  event.stopPropagation();
                                  onViewFileRequested(fileDesc)
                                }} >
                    <CardContent className={classes.contentBox}>
                      <Typography variant='h4' >{fileDesc.name}</Typography>
                        
                      <div style={{display:'flex', flexDirection: 'row', alignItems: 'center'}} >
                        <Button component="span"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onDownloadFileRequested(fileDesc)
                                }}
                                style={{display:'flex', flexDirection: 'row', alignItems: 'center'}} >
                          <CloudDownloadIcon />
                        </Button>

                        <Button component="span"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteFileClick(event, fileDesc)
                                }}
                                disabled={isReadOnly}>
                          <DeleteIcon />
                        </Button>
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
          } )
        }


      { (! isReadOnly) &&
        <React.Fragment>          
          <DropzoneArea
            fileLimit={10}
            maxFileSize={10000000}
            acceptedFiles={['image/*', 'video/*', 'application/*']}
            initialFiles={[]}
            
            dropzoneClass={classes.dropZone}
            dropzoneText="Drag and drop or click"
            dropzoneParagraphClass={classes.dropZoneText}

            showPreviewsInDropzone={false}

            showAlerts={false}

            onChange={selectedFiles => {
              if( selectedFiles.length === 0 )
                return;
              onUploadRequested(selectedFiles);
            }}
          />

        </React.Fragment>
      }


      <ConfirmDialog
          title='Delete file?'
          open={pendingDeleteFileDesc !== undefined}
          onConfirm={() => { 
            onDeleteRequested(pendingDeleteFileDesc); 
            setPendingDeleteFileDesc(undefined);
          }}
          onCancel={() => setPendingDeleteFileDesc(undefined) }
        >Are you sure you want to delete this file?</ConfirmDialog>


    </div>
  );
}

export default MyFileUpload;