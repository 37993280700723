import React from 'react';
// Material UI
import { Button, Typography } from '@material-ui/core';


const SignIn = ({signIn}) => {


	const handleSignInWithGoogle = (e) => {
		e.preventDefault();
		signIn();
	}


	return (
		<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '50px'}} >
				<img src='/logo.png' style={{ height: '50px', marginRight: '5px' }} alt='Logo' />
				<Typography variant='h1' color='primary' >Entryway Loans</Typography>
			</div>
			<Button variant="contained" color="primary" onClick={(e) => handleSignInWithGoogle(e)} style={{ marginTop: '50px' }} >
                Sign In With Google
			</Button>
		</div>
	);
}

export default SignIn;

