const config = {
  apiKey: "AIzaSyDy6kwiy2cp2MKe9w4AKf11bdlefOVA0fU",
  authDomain: "entryway-dev.firebaseapp.com",
  databaseURL: "https://entryway-dev.firebaseio.com",
  projectId: "entryway-dev",
  storageBucket: "entryway-dev.appspot.com",
  messagingSenderId: "295760381332",
};


export default config;