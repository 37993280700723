import React, { useState, useEffect, useContext } from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
// Reach Router
import { navigate } from "@reach/router"
// Local
import AppContext from './AppContext';
import LoadingOverlay from './shared/LoadingOverlay';
import FullScreenPopup from './shared/FullScreenPopup';
import ClientApplicationForm from './shared/forms/ClientApplicationForm';
//import Footer from './shared/Footer';


const styles = (theme) => ({
    header: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },

    
    container: {
      backgroundColor: '#f0f0f0',
    },

    main: {
      // Width
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        width: theme.breakpoints.values.md - theme.spacing(2) * 2,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
      },

      display: 'flex',
      flexDirection: 'column',
    },

  // Footer
  footer: {
    // Height
    height: '100%',
    // Width
    width: '100%',
    paddingLeft: '2px',
    paddingRight: '2px',
    // Children
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerSaveButton: {
    width: '90px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});
const useStyles = makeStyles(styles);



export default function ClientApplicationPage({clientId}) {
  const classes = useStyles();

  const {db, functions} = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(false);
  const [applicationData, setApplicationData] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [isDirty, setIsDirty] = useState(false);


  useEffect(() => {
    if( clientId === undefined ) {
      setApplicationData(undefined);
      setStatus(undefined);
    }
    else {
      var unsub = db.collection('clients_shared').doc(clientId).onSnapshot( (doc) => {
        if( doc.exists ) {
          const clientShared = doc.data();
          setApplicationData(clientShared?.application?.data || {});
          setStatus( clientShared?.application?.status || '' );
        }
        else {
          setApplicationData({});
          setStatus('');
        }
      });
      return function cleanup() {
        unsub();
      }
    }
  }, [db, clientId]);

  const handleClose = () => {
    save().then(() => navigate(-1));
  }

  const handleSubmit = () => {
    save().then( () => {
      setShowLoading(true);
      functions.submitClientApplication({ clientId: clientId}).then( (res) => {
        if( res.data === undefined || res.data.success === undefined )
          console.error('Error submitting application: malformed response.');
        else if( res.data.success === false )
          console.error('Error submitting application: ' + res.data.message);
        else
          console.log('Application submitted.');
      }).catch( (error) => {
        console.error('Error submitting application', error);
      }).finally( () => {
        setShowLoading(false);
        navigate(-1);
      });
    });
  }

  const handleOnChange = (name, value) => {
    const newApplicationData = JSON.parse(JSON.stringify(applicationData));
    newApplicationData[name] = value;
    setApplicationData(newApplicationData);
    setIsDirty(true);
  }

  const save = () => {
    if( applicationData === undefined )
      throw new Error('Application data is undefined.');
    if( ! isDirty )
      return new Promise( (resolve) => resolve() );

    setShowLoading(true);
    
    return functions.saveClientApplication({ clientId: clientId, data: applicationData}).then( (res) => {
      if( res.data === undefined || res.data.success === undefined )
        console.error('Error saving application data: malformed response.');
      else if( res.data.success === false )
        console.error('Error saving application data: ' + res.data.message);
      else
        console.log('Application data saved.');
    }).catch( (error) => {
      console.error('Error saving application data', error);
    }).finally( () => setShowLoading(false) );
  }

  if( applicationData === undefined )
    return ( <LoadingOverlay open={true} /> );
  else {
    const isReadOnly = status === 'submitted' || status === 'accepted';

    return (
      <FullScreenPopup  title="Borrower Application" onCancel={() => handleClose()}
        footerRight={[  <Button onClick={(e) => handleClose()} color='primary' variant='outlined' className={classes.footerSaveButton} >Close</Button>,
                        <Button onClick={(e) => handleSubmit()} color='primary' variant='contained' className={classes.footerSaveButton} disabled={isReadOnly} >Submit</Button>  ]} >
        
        <div className={classes.container} >
          <div className={classes.main} >
            <ClientApplicationForm value={applicationData} onChange={(name, value) => handleOnChange(name, value)} disabled={isReadOnly} />
          </div>
        </div>

        <LoadingOverlay open={showLoading} />
      </FullScreenPopup>
    );
  }

}
