// React
import React, { useState, useEffect } from 'react';
// Reach Router
import { Router, navigate } from "@reach/router"
// Local
import AppContext from './AppContext';
import LoadingOverlay from './shared/LoadingOverlay';
import Home from './Home';
import SubmissionWrapper from './SubmissionWrapper';
import NotFound from './NotFound';
import ClientApplicationPage from './ClientApplicationPage';
import { handleFirestoreUpdate, handleFirestoreUpdateToArray } from './shared/firebase/FirebaseUtil';


const AppMain = ({fb, user, client, clientId}) => {
  const {firebase, auth, db, storage, functions} = fb;

  const [reference, setReference] = useState(undefined);
  const [clientShared, setClientShared] = useState(undefined);
	const [submissions, setSubmissions] = useState(undefined);

  // Get reference data
  useEffect(() => {
    var unsubReference = db.collection('reference')
      .onSnapshot( (querySnapshot) => handleFirestoreUpdate(setReference, querySnapshot) );

    return function cleanup() {
      unsubReference();
    }
  }, [db, user]);

  // Subscribe to submission_shared
  useEffect(() => {
    if( clientId === undefined || clientId === 'not-found' )
      setSubmissions(undefined);
    else {
      var unsub = db.collection('submissions_shared').where('clients', 'array-contains', clientId)
        .onSnapshot( (querySnapshot) => handleFirestoreUpdateToArray(setSubmissions, querySnapshot, 'shared') );

      return function cleanup() {
          unsub();
      }
    }

	}, [db, clientId]);

  // Subscribe to client_shared for current user
  useEffect(() => {
    if( clientId === undefined || clientId === 'not-found' )
      setClientShared(undefined);
    else {
      var unsub = db.collection('clients_shared').doc(clientId).onSnapshot( (doc) => {
        if( doc.exists )
          setClientShared(doc.data());
        else
          setClientShared({});
      });
      return function cleanup() {
        unsub();
      }
    }
  }, [db, clientId]);

  if( reference === undefined || clientShared === undefined )
    return ( <LoadingOverlay open={true} /> );
  else {
    return (
      <AppContext.Provider value={{ 
                      clientId: clientId,
                      firebase: firebase, 
                      db: db, 
                      storage: storage,
                      functions: functions,
                      loggedInUser: user, 
                      reference: reference,
                      doSignOut: () => { navigate('/'); auth.signOut(); }
                      }}>
        <Router style={{height: '100%'}}>
          <Home            		path='/'							   clientId={clientId} client={client} clientShared={clientShared} submissions={submissions} />
          <SubmissionWrapper      		path='/submissions/:submissionId/*' clientId={clientId} client={client} clientShared={clientShared}  submissions={submissions} />
          <ClientApplicationPage path='/client/:clientId/application' clientShared={clientShared} />
          <NotFound default />
        </Router>
      </AppContext.Provider>
    );
  }
}

export default AppMain;

