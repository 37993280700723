import React, { useState, useEffect } from 'react';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

import { GroupedTextField } from '.';


export const DateField = ({label, name, value, onChange, className, style, mode, variant, disabled }) => {
  const [keyboardValue, setKeyboardValue] = useState('');    // Stored as "MM/DD/YYYY"


  useEffect(() => {
    if( mode === 'keyboard' ) {
      const mom = moment(value, 'YYYYMMDD', true);
      if( mom.isValid() )
        setKeyboardValue(mom.format('MM/DD/YYYY'));
      else
        setKeyboardValue('');
    }
  }, [value, mode]);


  if( mode === 'keyboard' ) {

    const handleOnChange = (value) => {
      setKeyboardValue(value)
      // If it is blank or valid, call onChange
      if( value === '' )
        onChange('');
      else {
        const mom = moment(value, 'MM/DD/YYYY', true);
        if( mom.isValid() )
          onChange(mom.format('YYYYMMDD'));
      }
    }

    return (
      <GroupedTextField
          label={label}
          name={name}
          value={ keyboardValue }
          onChange={ (value) => handleOnChange(value) }
          className={className}
          style={style}
          
          breakAfter={[2,4]}
          breakChar="/"
          maxLength={9}

          allowSkip='true'
          skipChar='0'
          disabled={disabled}
          />
    );
  }
  else if( mode === 'touch' || mode === '' || mode === undefined || mode === null ) {
    const mom = moment(value, 'YYYYMMDD', true);

    return (
      <DatePicker
        label={label}
        name={name}
        value={ mom.isValid() ? mom : null }
        onChange={ (date, value) => onChange( date === null ? '' : date.format('YYYYMMDD') ) }
        className={className}
        style={style}

        inputVariant={variant}
        variant='standard'
        format='MM/DD/YYYY'
        autoOk = 'true'
        clearable={true}
        disabled={disabled}
      />
    );
  }
  else
    throw new Error('Invalid variant ' + variant + '.');

}
