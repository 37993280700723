// React
import React, { useContext, useState, useEffect } from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Local
import Frame from './shared/Frame';
import AppContext from './AppContext';
import CardListSection from './shared/CardListSection';


const styles = (theme) => ({

  statusRow: {
    marginTop: theme.spacing(2),

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  statusNote: {
    fontStyle: 'italic',
  }
  
});
const useStyles = makeStyles(styles);


export default function SubmissionMain({submissionId, submission, clientUploads, client, clientShared, linkedClients}) {
  const {reference, clientId} = useContext(AppContext);
  const classes = useStyles();

  const refApplicationStatus = reference['application-status'];
  const refClientStatus = reference['client-status'];
  const refClientUploadStatus = reference['client-upload-status'];
  const refDocTypes = reference['doc-types'];

  const [applications, setApplications] = useState(undefined);
  const [isLoadingApplications, setIsLoadingApplications] = useState(undefined);

  useEffect(() => {
    // Merge loan application and client applications into one list for display
    let newApplications =[];
    newApplications.push({
            id: 'loan_' + submissionId, 
            title: 'Loan', 
            status: submission?.shared?.application?.status || 'new',
            url: '/submissions/' + submissionId + '/application' });

    if( clientShared?.application )
      newApplications.push({
            id: clientId,
            title: 'Borrower (' + client.name + ')',
            status: clientShared?.application?.status || 'new',
            url: '/client/' + clientId + '/application' });

    if( linkedClients )
      linkedClients.forEach( client => {
         newApplications.push({
              id: client.clientId,
              title: 'Borrower (' + client.name + ')',
              status: client.applicationStatus || 'new',
              url: '/client/' + client.clientId + '/application',
              disabled: true });
        });

    setApplications(newApplications);
    setIsLoadingApplications(linkedClients === undefined );
  }, [clientId, submissionId, submission, clientShared, linkedClients, client]);

  if( submission === undefined )
    return ( <Frame title='Loan Application' backLabel='Home' backTo='/' ><div>Loading...</div></Frame> );
  else if( submission === 'not found' )
    return ( <Frame title='Loan Application' backLabel='Home' backTo='/' ><div>Submission not found.</div></Frame> );
  else {
    const title = submission?.shared?.application?.data?.propAddress?.street || 'New Application';
    return (
      <Frame title={title} backLabel='Home' backTo='/' >
            <div className={classes.statusRow}>
              <Typography variant="body1" className={classes.subTitle}>Status: </Typography>&nbsp;&nbsp;&nbsp;
              <Typography variant="body1"
                style={{ textTransform: 'uppercase',
                         color: refClientStatus[submission?.shared?.clientStatus].color }} 
                   >{refClientStatus[submission?.shared?.clientStatus].label}</Typography>
            </div>
          { submission?.shared?.clientStatusNote && submission.shared.clientStatusNote.length > 0 &&
            <div className={classes.statusRow}>
              <Typography variant="body1" className={classes.subTitle} style={{fontStyle: 'italic'}} >{submission.shared.clientStatusNote} </Typography>&nbsp;&nbsp;&nbsp;
            </div>
          }

          <CardListSection
              title='Applications'
              getCardTitle={(value) => value.title }
              getCardUrl={(value) => value.url }
              getCardRight={(value) => (
                      <Typography variant="body1"
                        style={{ textTransform: 'uppercase',
                                 color: refApplicationStatus[value.status].color }} 
                           >{refApplicationStatus[value.status].label}</Typography> ) }
              getIsDisabled={(value) => value.disabled}
              values={applications} 
              isLoading={isLoadingApplications}     />   

          <CardListSection
              title='Document Requests'
              getCardTitle={(value) => value?.shared?.label || refDocTypes[value?.shared.docType]?.label || 'Unknown' }
              getCardUrl={(value) => '/submissions/' + submissionId + '/doc-request/' + value.id }
              getCardRight={(value) => (
                      <Typography variant="body1"
                        style={{ textTransform: 'uppercase',
                                 color: refClientUploadStatus[value?.status].color }} 
                           >{refClientUploadStatus[value?.status].label}</Typography> ) }
              values={clientUploads}      />   

      </Frame>
    );
  }
}



