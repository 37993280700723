// React
import React from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';


const styles = (theme) => ({

  container: {
    height: '100%',
    width: '100%',
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },

  left: {
    width: '32%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  center: {
    width: '32%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  right: {
    width: '32%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }

});
const useStyles = makeStyles(styles);


export const ThreePartRow = (props) => {
  const classes = useStyles();

  const left = props.left;
  const center = props.center;
  const right = props.right;

  let newProps = Object.assign({}, props);
  delete newProps.left;
  delete newProps.center;
  delete newProps.right;

  return (
      <div {...newProps} >
        <div className={classes.container}>
          <div className={classes.left}>
            {left}
          </div>
          <div className={classes.center}>
            {center}
          </div>
          <div className={classes.right}>
            {right}
          </div>
        </div>
      </div>
  );
}

export default ThreePartRow;
