// React
import React from 'react';
// Material UI
//import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
// Local
import SectionHeaderRow from './SectionHeaderRow';
import { SquareIconButton } from './controls';
import LoadingCard from './LoadingCard';
import CardItem from './CardItem';


//const styles = (theme) => ({ });
//const useStyles = makeStyles(styles);


export const CardListSectionNew = (props) => {
  //const classes = useStyles();

  const title = props.title;
  const onAdd = props.onAdd;
  const getCardTitle = props.getCardTitle;
  const getCardUrl = props.getCardUrl;
  const getCardRight = props.getCardRight;
  const onCardEdit = props.onCardEdit;
  const onCardDelete = props.onCardDelete;
  const isLoading = props.isLoading || false;
  const values = props.values;
  const getIsDisabled = props.getIsDisabled;

  let newProps = Object.assign({}, props);
  delete newProps.title;
  delete newProps.onAdd;
  delete newProps.getCardTitle;
  delete newProps.getCardUrl;
  delete newProps.getCardRight;
  delete newProps.onCardEdit;
  delete newProps.onCardDelete;
  delete newProps.isLoading;
  delete newProps.values;
  delete newProps.getIsDisabled;

  const handleEditClick = (e, id) => {
    e.preventDefault();
    onCardEdit(id);
  }

  const handleDeleteClick = (e, id) => {
    e.preventDefault();
    onCardDelete(id);
  }

  return (
    <div {...newProps} >
       <SectionHeaderRow 
              text={title}
              right={onAdd && <Button onClick={() => onAdd()} startIcon={<AddIcon />} >Add</Button>} />
        <div>
          { values === undefined ?
            <LoadingCard />
            : values.length === 0 ?
            <div>None found.</div>
            : values.map( value =>
                <CardItem
                  key={value.id}
                  title={getCardTitle && getCardTitle(value)}
                  url={getCardUrl && getCardUrl(value)}
                  right={
                    <React.Fragment>
                      { getCardRight && getCardRight(value)}
                      {(onCardEdit || onCardDelete) && <div style={{width: '40px'}}/> }
                      {onCardEdit && <SquareIconButton key={2} onClick={(e) => handleEditClick(e, value)} style={{paddingTop:0, paddingBottom:0, paddingLeft: 12}} ><EditIcon fontSize='small' /></SquareIconButton>}
                      {onCardDelete && <SquareIconButton key={3} onClick={(e) => handleDeleteClick(e, value)} style={{paddingTop:0, paddingBottom:0, paddingLeft: 12}} ><DeleteIcon /></SquareIconButton>}
                     </React.Fragment> }
                  disabled={getIsDisabled && getIsDisabled(value)}  /> 
            )}
          { (isLoading || values === undefined) &&
            <LoadingCard />
          }
        </div>
    </div>
  );

}


export default CardListSectionNew;
